.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the container takes the full height of the viewport */
}

.content-wrap {
  flex: 1; /* Allow the content to grow and fill the available space */
  display: flex;
  flex-direction: column;
}

.team-container {
  text-align: center;
  padding: 20px;
}

.team-title {
  font-size: 2rem;
  margin-bottom: 20px;
}

.team-members {
  display: flex; /* Use flexbox for all members */
  flex-wrap: wrap; /* Allow wrapping to the next line */
  justify-content: center; /* Center align the members */
  position: relative;
}

.top-members {
  display: flex; /* Use flexbox for Captain and Vice Captain */
  justify-content: center; /* Center them horizontally */
  margin-bottom: 20px; /* Space between top members and core members */
}

.team-member-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align the name and role */
  margin: 10px; /* Space between cards */
}

.team-member {
  margin: 0; /* Remove margin for the card */
  padding: 0; /* Remove padding for the card */
  width: 150px; /* Fixed width for square shape */
  height: 150px; /* Fixed height for square shape */
  position: relative;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent background for consistency */
  overflow: hidden; /* Hide overflow to crop images */
  border-radius: 15px; /* Rounded edges for the card */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transition for zoom and shadow */
}

.team-member:hover {
  transform: scale(1.05); /* Zoom in effect */
  box-shadow: 0 0 15px rgba(255, 255, 0, 0.8); /* Glow effect */
}

.team-member img {
  width: 100%; /* Make image take full width */
  height: 100%; /* Make image take full height */
  object-fit: cover; /* Crop the image to cover the area */
  border-radius: 15px; /* Match the card's border radius */
}

.member-name {
  margin-top: 10px; /* Space between the card and name */
  font-size: 1.2rem; /* Adjust font size as needed */
  color: white; /* Default text color for other members */
}

/* Specific styles for Captain's name */
.captain .member-name {
  font-size: 1.5rem; /* Make Captain's name larger */
  font-weight: bold; /* Make Captain's name bold */
  color: gold; /* Set Captain's name color to gold */
}

.member-role {
  font-size: 1rem; /* Adjust font size as needed */
  color: #ccc; /* Slightly lighter color for role */
}

.captain {
  width: 200px; /* Larger size for captain */
  border: 3px solid gold; /* Highlight captain */
}

.vice-captain {
  width: 200px; /* Larger size for vice captain */
  border: 3px solid silver; /* Highlight vice captain */
}

/* Core Members Section */
.core-members {
  display: flex; /* Use flexbox for core members */
  flex-wrap: wrap; /* Allow wrapping to the next line */
  justify-content: center; /* Center align the core members */
  margin-top: 20px; /* Space above core members */
}

.core-member-container {
  flex: 0 0 calc(20% - 20px); /* Set width to 20% to fit 5 cards in a row with margin */
  margin: 10px; /* Space between cards */
}

/* Add lines connecting members */
.team-member::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  width: 2px;
  height: 50px; /* Adjust height as needed */
  background: #ccc;
  z-index: -1;
}
