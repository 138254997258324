@tailwind base;
@tailwind components;
@tailwind utilities;
@keyframes gradient-x {
    0%, 100% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }
  
  .animate-gradient-x {
    animation: gradient-x 15s ease infinite;
    background-size: 400% 400%;
  }

@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

.hover-zoom {
  transition: transform 0.3s ease-in-out;
}

.hover-zoom:hover {
  animation: zoomIn 0.3s forwards;
}