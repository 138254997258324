.nav-item {
  font-size: 1.125rem;
  transition: all 0.3s ease;
  position: relative;
  padding: 0.25rem 0.5rem;
}

.nav-item:hover {
  transform: scale(1.1);
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
}

.nav-item::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.nav-item:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.animated-button {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  padding: 0.5em 2em;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  outline: none;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transform: scale(1);
  box-shadow: 0 0 0 rgba(255, 255, 255, 0);
}

.animated-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
}

.join-tournament-btn {
  background-color: rgba(255, 255, 255, 0.15);  /* Slightly reduced opacity */
  color: white;
  border: none;
  padding: 0.5em 2em;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  outline: none;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transform: scale(1);
  box-shadow: 0 0 0 rgba(255, 255, 255, 0);
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);  /* Added text shadow */
}

.join-tournament-btn:hover {
  background-color: rgba(255, 255, 255, 0.25);  /* Slightly increased opacity on hover */
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
  text-shadow: 0 0 15px rgba(0, 0, 0, 0.7);  /* Enhanced text shadow on hover */
}

.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(0, 128, 128, 0.6) 0%, rgba(0, 0, 0, 0.3) 60%);
  z-index: 1;
}

.content-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Change this line */
  -webkit-overflow-scrolling: touch; /* Add this line for smooth scrolling on iOS */
}

/* Responsive navigation */
@media (max-width: 1024px) {
  .nav-container {
    flex-direction: column;
    align-items: center;
  }

  .nav-links {
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
  }

  .nav-item {
    margin: 0.5rem 0;
    padding: 0.5rem 0;
  }
}

/* Responsive button */
.join-tournament-btn {
  font-size: clamp(0.875rem, 2vw, 1.25rem);
  padding: 0.5em 1em;
}

/* Responsive text */
.responsive-text {
  font-size: clamp(0.875rem, 2vw, 1rem);
}

.responsive-title {
  font-size: clamp(1.5rem, 3vw, 2.5rem);
}

/* Form styles */
.form-container {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 200px); /* Change height to max-height */
  overflow-y: auto;
}

/* Custom scrollbar for webkit browsers */
.form-container::-webkit-scrollbar {
  width: 8px;
}

.form-container::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

.form-container::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
}

.form-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

/* Add these new styles */
body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Change from hidden to auto */
}

main {
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 200px); /* Adjust based on your header and footer height */
}

.glow-on-hover {
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #000;
}

.glow-on-hover:active:after {
    background: transparent;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

/* Updated styles for the checkbox */
.checkbox-wrapper {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
}

.checkbox-wrapper input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.checkbox-wrapper label {
  position: relative;
  display: inline-block;
  padding-left: 35px;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
}

.checkbox-wrapper label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border: 2px solid #fff;
  background-color: transparent;
  border-radius: 4px;
}

.checkbox-wrapper input[type="checkbox"]:checked + label::after {
  content: '\2714';
  position: absolute;
  left: 4px;
  top: -1px;
  color: #fff; /* Changed to white */
  font-size: 18px; /* Reduced size */
  font-weight: bold;
}
