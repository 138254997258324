.game-logo-container {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.game-logo {
  width: 100%;
  height: auto; /* Change from fixed height to auto */
  max-height: 16rem; /* Add max-height instead of fixed height */
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.game-logo-container:hover {
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.7);
}

.game-logo-container:hover .game-logo {
  transform: scale(1.1);
}

.game-logo-container::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, #ff00ff, #00ffff, #ff00ff, #00ffff);
  z-index: -1;
  filter: blur(10px);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 0.7rem;
}

.game-logo-container:hover::before {
  opacity: 1;
  animation: animate-outline 2s linear infinite;
}

@keyframes animate-outline {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

/* Replace the existing .join-tournament-btn styles with these new styles */
.glow-on-hover {
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #000
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.nav-item {
  font-size: 1.125rem; /* text-lg equivalent */
  transition: all 0.3s ease;
  position: relative;
  padding: 0.25rem 0.5rem;
}

.nav-item:hover {
  transform: scale(1.1);
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
}

.nav-item::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.nav-item:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(0, 128, 128, 0.6) 0%, rgba(0, 0, 0, 0.3) 60%);
  z-index: 1;
}

.content-wrap {
  position: relative;
  z-index: 2;
}

/* Add these new styles at the end of the file */
.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
}

@media (min-width: 1024px) {
  main {
    flex-direction: row;
    padding: 2rem;
  }
}
